import { NgModule, ErrorHandler, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { TraceService } from '@sentry/angular';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import { FlxionCommonModule } from './common/flxion-common.module';
import { ErrorHandlerService } from './services/error-handler.service';
import { NavigationGuard } from './guards/navigation.guard';
import { PendingChangesGuard } from './guards/pending-changes-guard';

import { rootRouterConfig } from './app.routing';
import { AppComponent } from './app.component';
import { initializeApp } from './app.initializer';

export let InjectorInstance: Injector;

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlxionCommonModule,
    HttpClientModule,
    PerfectScrollbarModule,
    RouterModule.forRoot(rootRouterConfig)
  ],
  declarations: [AppComponent],
  providers: [
    PendingChangesGuard,
    NavigationGuard,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: TraceService, deps: [Router] },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: { suppressScrollX: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { autoActiveFirstOption: true } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TraceService],
      multi: true,
    },

    // REQUIRED IF YOU USE JWT AUTHENTICATION
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Note: scrolling behavior on 'back' is implemented in MeetingsModule.

  constructor(private injector: Injector)
  {
    InjectorInstance = this.injector;
  }
}
